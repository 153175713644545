<script>
  import { project } from "$lib/services/store";
  import { afterNavigate } from "$app/navigation";
  import Search from '$lib/admin/components/blocks/helpers/Search.svelte'
  import DarkMode from '$lib/main/DarkMode.svelte'
  import { slide } from "svelte/transition"

  let innerWidth = 0;
  let openKey = {}
  let opened = false

  function toggleNav() {
    openKey = {}
    opened = !opened
  }

  afterNavigate(() => {
    openKey = {}
    opened = false
  })
</script>

<svelte:window bind:innerWidth />

<header class="style-a">
  <div class="container">
    <a href="/" class="logo">
      <img alt={$project.name + " Logo"} src={ $project.data?.imageFolders?.assets + $project.name_lower + "-logo.png?h=22&q=100&auto=format"}>
    </a>

    <div class="navigation">
      {#if $project.menu?.darkMode == "active"}
        <DarkMode key={$project.key} />
      {/if}

      <div class="main-nav">
        <ul class="container">
          {#if $project.menu?.menu}
            {#each $project?.menu.menu ?? [] as item}
              <li class:hidden={!item?.main}><a href={item.path} on:click={() => (opened = false)}>{item.name}</a></li>
            {/each}
          {/if}
        </ul>
      </div>

      {#if $project.menu?.search == "active"}
        <Search />
      {/if}

      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span class="hamburger" on:click={toggleNav}>
        <div class:opened></div>
      </span>
    </div>
  </div>

  {#key openKey}
    <nav
      class="sub-nav"
      class:opened={ opened  }
      in:slide={{ duration: (innerWidth > 991) ? 150 : 400 }}
      out:slide={{ duration: (innerWidth > 991) ? 150 : 200 }}>
      <ul class="container">
        {#if $project.menu?.menu}
          {#each $project?.menu.menu ?? [] as item}
            <li class:hidden={item?.main}><a href={item.path} on:click={() => (opened = false)}>{item.name}</a></li>
          {/each}
        {/if}
      </ul>
    </nav>
  {/key}
</header>