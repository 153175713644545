<script>
  import Translate from '$lib/admin/components/blocks/helpers/Translate.svelte';
  import { project } from '$lib/services/store'

  export let operator = null;
</script>

<!-- Only one Ribbon will be shown at a time, so order these in priority  -->

{#if 3 === 2}
  <div class="ribbon">
    <span><Translate text="Hot!" /></span>
  </div>
{:else if 3 === 11}
  <div class="ribbon popular">
    <span><Translate text="Popular!" /></span>
  </div>
{:else if Number(operator.founded) > 2021}
  <div class="ribbon">
    <span><Translate text="New!" /></span>
  </div>
{:else if 3 === 5}
  <div class="ribbon">
    <span><Translate text="Crispy!" /></span>
  </div>
{:else if
  operator?.data?.deposit
  && operator?.data?.deposit[$project.settings.market]?.includes("Interac")
  && $project.settings.market == 'ca'}
  <div class="ribbon interac">
    <img
      src={ $project.data.imageFolders.assets + "interac.png?w=50&h=50&q=85&auto=format"}
      alt={"Interac deposit available at " + operator.name}
      title={"Interac deposit available at " + operator.name}>
  </div>
{/if}