<script>
  import { project, projectToplists, orgOperators } from "$lib/services/store";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Icons from '$lib/main/components/Icons.svelte';
  import Rating from '$lib/admin/components/blocks/helpers/Rating.svelte';
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import Heading from '$lib/admin/components/blocks/helpers/Heading.svelte';
  import ToplistRow from '$lib/admin/components/blocks/helpers/ToplistRow.svelte';
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import Links from '$lib/admin/components/blocks/blocks/Links.svelte';

  let showLinks = false;
  let showMoreCasinos = false;

  export let props = null;
  export let adminView = false;
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      type="select"
      label="Toplist type"
      id="type"
      on:change
      bind:value={props.toplistType}>
      <option value="table">Table</option>
      <option value="cards">Cards</option>
      <option value="list">List</option>
    </Input>
  </Group>
  <Group colspan="4-8" addClass="form-item">
    <Input
      type="select"
      label="Toplist topic"
      id="type"
      on:change
      bind:value={props.toplistFocus}>
      <option value="casino">Casino Bonus</option>
      <option value="sport">Sports Bonus</option>
      <option value="slots">Slots</option>
      <option value="lotto">Lotto</option>
    </Input>
  </Group>
  <Group colspan="4-8" addClass="form-item">
    <Input
      type="select"
      label="Toplist data"
      id="data"
      bind:value={props.toplistId}>
      {#if $projectToplists}
        <option value={null}>None</option>
        {#each $projectToplists as item, i}
          <option value="{item.id}">{item.name}</option>
        {/each}
      {/if}
    </Input>
  </Group>
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="Toplist length"
      id="length"
      type="number"
      min="1"
      max="20"
      on:keyup
      bind:value={props.toplistLength} />
  </Group>
{:else}
  {#if props.toplistType == "table"}
    <Block blockClass="block-toplist" {props}>
      <div class="toplist-holder">
        <Heading {props} />

        <!-- TODO: create helper function? -->
        {#if props.toplistNavBar}
          <Links
            props={{
              "linksType": "toplistSort",
              "links": $project.menu?.toplistMenu
            }}
            bind:showLinks />
        {/if}

        <div class="toplist">
          {#if props.toplistId && $projectToplists && $projectToplists?.length > 0 && $orgOperators}
            {#each $projectToplists.find(x => x.id === props.toplistId)?.toplist || [] as operatorId, i}
              {#if i < props.toplistLength || showMoreCasinos && Array.isArray($orgOperators)}
                {@const operator = $orgOperators.find(x => x.id === operatorId)}
                <ToplistRow {props} {operator} />
              {/if}
            {/each}
          {:else if props.toplistLength == 1 && props.toplistOperatorId && Array.isArray($orgOperators)}
            <ToplistRow {props} operator={$orgOperators.find(x => x.id === props.toplistOperatorId)} />
          {/if}
        </div>

        <!-- TODO: create helper function? can use a regular Button component -->
        {#if props.toplistLoadMore}
          {#if !showMoreCasinos}
            <div class="grid">
              <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
                <button class="load-more" on:click={() => { showMoreCasinos = !showMoreCasinos }}>
                  <Icons name="rotate-cw" strokeWidth="3" /><span><Translate text="Load more casinos" /></span>
                </button>
              </div>
            </div>
          {/if}
        {/if}
        {#if props.toplistLinkUrl}
          <div class="grid">
            <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
              <a href={ props.toplistLinkUrl } class="read-more" on:click={() => { showMoreCasinos = !showMoreCasinos }}>
                <span>{ props.toplistLinkAnchor }</span>
              </a>
            </div>
          </div>
        {/if}
      </div>
    </Block>
  {:else if props.toplistType == "cards"}
    <Block blockClass="block-toplist casino cards" {props}>
      <div class="toplist-holder">
        <Heading {props} />

        <div class="toplist">
          {#if props.toplistId && $projectToplists && $projectToplists?.length > 0 && $orgOperators}
            {#each $projectToplists.find(x => x.id === props.toplistId)?.toplist || [] as operatorId, i}
              {#if  i < props.toplistLength || showMoreCasinos}
                {@const operator = $orgOperators.find(x => x.id === operatorId)}
                <div class="toplist-item">
                  <div class="toplist-container">
                    <div class="toplist-img { "_" + operator["name_lower"] }">
                      <h4>{ operator["name"] }</h4>
                      <img src={ $project.data?.imageFolders?.operators + operator["name_lower"] + ".png?q=85&auto=format" } alt={operator["name"]}>
                      <div class="toplist-rating">
                        <Rating {operator} />
                      </div>
                    </div>
                    <!-- TODO: include system for campaigns -->
                    {#if 2 > 3}
                      <div class="toplist-campaign">
                        <span class="label label-info pull-left" style="background-color: #2CA5E4;border-radius: 7px;margin-right: 10px;font-weight:400;">
                          <span class="glyphicon glyphicon-bullhorn" aria-hidden="true"></span>
                        </span>
                        <p><b>LeoJackpot</b> - Leo Vegas egen jackpot är nu uppe i över 135 miljoner kr! <a href="https://casinofeber.se/spela/leo-vegas/747" target="_blank" rel="nofollow noreferrer">Läs mer hos Leo Vegas</a></p>
                      </div>
                    {/if}
                    <div class="toplist-bonus">
                      <div class="bonus-full">
                        {#if operator?.bonus?.casino?.[$project.settings.market]?.amount}
                          <span class="bonus-details">Up to <span class="bonus-amount">{ operator?.bonus?.casino[$project.settings.market]?.amount}</span> bonus

                          {#if operator?.bonus?.casino?.[$project.settings.market]?.["free-spins"]}
                            + <span class="free-spins">{operator?.bonus?.casino?.[$project.settings.market]?.["free-spins"]}</span> Free spins
                          {/if}
                          </span>
                        {:else}
                          Default fallback needed
                        {/if}
                      </div>
                      <div class="license">
                        {#if operator.licenses?.includes("SE")}
                          <img src="//cdn.casinofeber.se/pics/casinoinfo-svenskt.png?w=22&amp;h=22&amp;q=100&amp;auto=format" loading="lazy" alt="Svensk licens">
                          <span>Casinot har svensk licens</span>
                        {/if}
                      </div>
                    </div>
                    <div class="toplist-cta">
                      <a href="/go/{operator.name_lower}" target="_blank" rel="nofollow noreferrer noopener" class="visit">
                        <span>Till casinot</span>
                        <span>Vidare till { operator["name"] }</span>
                      </a>
                      <a href={ ($project.data?.categoryPages?.reviews.slug ? "/" + $project.data?.categoryPages.reviews.slug : "") + "/" + operator.name_lower} class="review">{ operator["name"] }</a>
                    </div>
                  </div>
                  <div class="toplist-terms">
                    Reklamlänk | 18+ | <a href="/go/{operator.name_lower}/villkor" target="_blank" rel="nofollow noreferrer">Regler &amp; villkor gäller</a> | Spela ansvarsfullt | <a href="https://www.stodlinjen.se" target="_blank" rel="nofollow noreferrer noopener">stodlinjen.se</a>
                  </div>
                </div>
              {/if}
            {/each}
          {/if}
        </div>

        <div class="grid">
          <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
            {#if props.toplistButtonAnchor && props.toplistButtonUrl}
              <a class="read-more" href={props.toplistButtonUrl}><span>{props.toplistButtonAnchor}</span></a>
            {/if}
          </div>
        </div>
      </div>
    </Block>
  {:else if props.toplistType == "list"}
    <Block blockClass="block-toplist list" {props}>
      <Heading {props} />
      <ul>
        {#if props.toplistId && $projectToplists && $projectToplists?.length > 0 && $orgOperators}
          {#each $projectToplists?.find(x => x.id === props.toplistId)?.toplist || [] as operatorId, i}
            {@const operator = $orgOperators.find(x => x.id === operatorId)}
            <li>
              <a class={"_" + operator.name_lower + " logo"} href="/">
                <img class={  + " lazyload" } alt={ operator.name } src={ $project.data?.imageFolders.operators + operator["name_lower"] + ".png?q=85&auto=format" }>
              </a>
              <h4><a class="logo" href="/">{ operator.name }</a></h4>
              <div class="bonus">{ operator.bonus?.[props.toplistFocus]?.[$project.settings.market]?.["short"] ?? "No bonus"}</div>
              <a class="visit" href="/"><Translate text="Visit" /></a>
            </li>
          {/each}
        {/if}
      </ul>
      {#if props.toplistLinkUrl}
        <div class="grid">
          <div class="g-col-12 g-col-sm-8 g-start-sm-3 g-col-md-6 g-start-md-4 g-col-lg-4 g-start-lg-5">
            <a href={ props.toplistLinkUrl } class="read-more" on:click={() => { showMoreCasinos = !showMoreCasinos }}>
              <span>{ props.toplistLinkAnchor }</span>
            </a>
          </div>
        </div>
      {/if}
    </Block>
  {:else}
    <p>No toplist chosen..</p>
  {/if}
{/if}