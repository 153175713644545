export const website = (project) => {
  return `<script type="application/ld+json">
    {
      "@context" : "http://schema.org",
      "@type" : "WebSite",
      "@id": "${project.settings.url + "#website"}",
      "name" : "${project.settings.domain}",
      "url" : "${project.settings.url}"
    }
  </script>`;
}

export const organization = (project) => {
  return `<script type="application/ld+json">
    {
      "@context" : "http://schema.org",
      "@type": "Organization",
      "@id": "${project.settings.url + "#organization"}",
      "name" : "${project.settings.domain}",
      "url" : "${project.settings.url}",
      "alternateName" : "${project.name}",
      "logo": "${project.data?.imageFolders?.assets + project.name_lower + "-logo.png"}",
      "foundingDate": "${project.settings.foundedYear}",
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "customer service",
        "email": "${project.data.contact.email}",
        "url": "${project.settings.url + project.data.contact.url}"
      },
      "sameAs" : []
    }
  </script>`;
}

export const article = (post, users, project) => {
  let article = `<script type="application/ld+json">`

  article = article + `{
    "@context" : "http://schema.org",
    "@type": "Article",
    "@id": "${project.settings.url}#article",
    "url": "${project.settings.url}",
    "name": "${post?.title}",`

  if (post?.user_id && Array.isArray(users)) {
    const user = users?.find(x => x.id === post.user_id)
    if (user?.data && project.key in user?.data) {
      article = article + `
        "author": {
          "@type": "Person",
          "name": "${user.full_name}",
          "jobTitle": "${user.data[project.key].role}",
          "description": "${user.data[project.key].bio}",
          "email": "${user.data[project.key].contact_email}",
          "sameAs": "${user.data[project.key].linkedin}"
        },
      "editor": "${user.full_name}",`
    }
  }

  article = article + `
    "datePublished": "${post?.created_at}",
    "dateModified": "${post?.updated_at}",
    "isPartOf": {
      "@id": "${project.settings.url}#website"
    },
    "inLanguage": "${project.settings.lang}"
  }
  </script>`

  return article
}

export const faq = (data) =>  {
  let mainEntity = []

  data.forEach(question => mainEntity.push(JSON.stringify({
    "@type": "Question",
    "name": question.header,
    "acceptedAnswer": {
      "@type": "Answer",
      "text": question.content
    }
  })))

  return `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [${mainEntity}]
    }
  </script>`;
}