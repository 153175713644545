<script>
  import supabase from "$lib/services/db";
  import Group from "$lib/admin/components/Group.svelte";
  import Cards from "$lib/admin/components/Cards.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Manage from "$lib/admin/components/Manage.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Modal from "$lib/main/components/Modal.svelte";
  import { createEventDispatcher } from "svelte";

  export let name = null;
  export let email = null;
  export let subject = null;
  export let feedback = null;
  export let isAdminFeedback = null;
  export let type = "button"; // button/link

  let toggleFeedbackForm = false;
  let message = {};

  const saveFeedback = async () => {
    const { error } = await supabase.from("feedback").insert({
      name,
      subject,
      email,
      feedback,
      is_admin_feedback: isAdminFeedback ?? false,
    });
    if (error) {
      console.error(JSON.stringify(error));
      message = { success: false, display: "Failed to save feedback." };
    } else {
      message = { success: true, display: "Feedback sent!" };
    }
  };
</script>

<Manage {message} />

<!-- Display button/link to toggle feedback form -->
{#if type === "button"}
  <Button
    addClass="secondary large"
    on:click={() => (toggleFeedbackForm = !toggleFeedbackForm)}
  >
    <slot />
  </Button>
{:else}
  <a href={null} on:click={() => (toggleFeedbackForm = !toggleFeedbackForm)}>
    <slot />
  </a>
{/if}

<!-- core feedback form -->
<Modal addClass="feedback-form" bind:toggleModal={toggleFeedbackForm}>
  <svelte:fragment slot="header">Send us your feedback</svelte:fragment>
  <Cards
    type="form"
    useEnhance={async ({ cancel }) => {
      await saveFeedback();
      cancel();
      toggleFeedbackForm = false;
    }}
  >
    <Group addClass="form-item">
      <Input type="text" placeholder="Name" bind:value={name} />
    </Group>
    <Group addClass="form-item">
      <Input type="email" placeholder="your@email.com" bind:value={email} />
    </Group>
    <Group addClass="form-item">
      <Input type="text" placeholder="Subject" bind:value={subject} />
    </Group>
    <Group addClass="form-item">
      <Input type="textarea" placeholder="Your message.." bind:value={feedback} />
    </Group>
    <Group addClass="form-item">
      <Button type="submit" addClass="secondary">Send</Button>
    </Group>
  </Cards>
</Modal>
