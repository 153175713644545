<script>
  import { project } from '$lib/services/store';

  export let operator = null;
</script>

{#if $project.key == 'cf'}
  Reklamlänk | 18+ | <a href={ operator?.name_lower ? "https://casinofeber.se/spela/" + operator?.name_lower + "/villkor" : null } target="_blank" rel="nofollow noreferrer noopener">Regler &amp; villkor gäller</a> | Spela ansvarsfullt | <a href="https://www.stodlinjen.se" target="_blank" rel="nofollow noreferrer noopener">stodlinjen.se</a>
{:else if $project.key == 'ocb'}
  New customers. 18+. Gamble responsibly.
{:else if $project.key == 'cf-ca'}
  Affiliate link | 19+ | T&Cs Apply | Play responsible | This offer is not available for players residing in Ontario
{:else}
  New customers. 18+. Gamble responsibly.
{/if}