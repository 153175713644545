<script>
  import { project, translations } from "$lib/services/store";
  import Icons from '$lib/main/components/Icons.svelte';
  import Rating from '$lib/admin/components/blocks/helpers/Rating.svelte';
  import Translate from '$lib/admin/components/blocks/helpers/Translate.svelte';
  import Ribbon from '$lib/admin/components/blocks/helpers/Ribbon.svelte';
  import Terms from '$lib/admin/components/blocks/helpers/Terms.svelte';

  export let props = null;
  export let operator = null;

  // TODO: add a toplistRowType variable to differentiate later?
</script>

{#if operator}
<!-- TODO: needed this #if statement as browsing between pages which uses this component sometimes makes it crash in the unload/load period..  -->
<div class="toplist-item">
  <div class="toplist-container">
    <Ribbon {operator} />
    <div class="toplist-img { "_" + operator["name_lower"] }">
      <h4>{ operator["name"] }</h4>
      <img src={ $project.data?.imageFolders?.operators + operator["name_lower"] + ".png?w=250&q=85&auto=format" } alt={operator["name"]}>
      <Rating {operator} />
    </div>

    {#if operator?.bonus?.[props.toplistFocus]?.[$project.settings.market]?.campaign}
      {@const campaign = operator.bonus[props.toplistFocus][$project.settings.market].campaign}
      <div class="toplist-campaign">
        <div class="stamp">
          <Icons name="gift" strokeWidth="2" color="#fff" />
        </div>
        <div class="campaign-text">
          <p><b>{ campaign.title }</b> - { campaign.text }</p>
        </div>
        <a href={ campaign.link } target="_blank" rel="nofollow noreferrer">{ campaign.anchor }</a>
      </div>
    {/if}

    <div class={ "toplist-bonus " + props.toplistFocus + " _" + operator["name_lower"] } style={ "background-color: rgba(var(--_" + operator["name_lower"] + "), 0.15)" }>
      {#if props.toplistFocus == 'casino'}
        <div class="amount">
          {#if operator?.bonus?.casino?.[$project.settings.market]?.bonus}
            <span>{operator?.bonus?.casino?.[$project.settings.market]?.bonus}</span> bonus
          {:else if operator?.bonus?.casino?.[$project.settings.market]?.amount}
            <span>{(operator?.bonus?.casino[$project.settings.market]?.currency ? operator?.bonus?.casino[$project.settings.market]?.currency + " " : "") + operator?.bonus?.casino[$project.settings.market]?.amount}</span> bonus
          {:else if operator?.bonus?.casino?.[$project.settings.market]?.fallbacks?.bonus}
            {@html operator?.bonus?.casino[$project.settings.market]?.fallbacks?.bonus}
          {:else}
            <Translate text="No bonus" />
          {/if}
        </div>

        <div class="freespins" class:no-free-spins={!operator?.bonus?.casino?.[$project.settings.market]?.["free-spins"] && !operator?.bonus?.casino?.[$project.settings.market]?.fallbacks?.["free-spins"]}>
          {#if operator?.bonus?.casino?.[$project.settings.market]?.["free-spins"]}
            <span>{operator?.bonus?.casino[$project.settings.market]?.["free-spins"]}</span> { operator?.bonus?.casino[$project.settings.market]?.["free-spins-type"] !== undefined ? operator?.bonus?.casino[$project.settings.market]?.["free-spins-type"] : "Free spins"}
          {:else if operator?.bonus?.casino?.[$project.settings.market]?.fallbacks?.["free-spins"]}
            {@html operator?.bonus?.casino[$project.settings.market]?.fallbacks?.["free-spins"]}
          {:else}
            <span class="no-free-spins"><Translate text="No" /></span> free spins
          {/if}
        </div>
      {:else if props.toplistFocus == 'sport'}
        <div class="amount">
          {#if operator?.bonus?.sports?.[$project.settings.market]?.bonus}
            <span>{operator?.bonus?.sports?.[$project.settings.market]?.bonus}</span> {operator?.bonus?.sports?.[$project.settings.market]?.type ?? "bonus"}
          {:else if operator?.bonus?.sports?.[$project.settings.market]?.amount}
            <span>{(operator?.bonus?.sports[$project.settings.market]?.currency ? operator?.bonus?.sports[$project.settings.market]?.currency + " " : "") + operator?.bonus?.sports[$project.settings.market]?.amount}</span> {operator?.bonus?.sports?.[$project.settings.market]?.type ?? "bonus"}
          {:else if operator?.bonus?.sports?.[$project.settings.market]?.fallbacks?.bonus}
            {@html operator?.bonus?.sports[$project.settings.market]?.fallbacks?.bonus}
          {:else}
          <Translate text="No bonus" />
          {/if}
        </div>

        <div class="turnover">
          {#if operator?.bonus?.sports?.[$project.settings.market]?.["turnover"] == "0"}
            <Translate text="No turnover" />
          {:else if operator?.bonus?.sports?.[$project.settings.market]?.["turnover"]}
            <span>{operator?.bonus?.sports?.[$project.settings.market]?.["turnover"]} x</span> <Translate text="Wagering req." />
          {:else if operator?.bonus?.sports?.[$project.settings.market]?.fallbacks?.turnover}
            {@html operator?.bonus?.sports[$project.settings.market]?.fallbacks?.turnover}
          {:else}
            <span>?</span>
            <Translate text="Wagering req." />
          {/if}
        </div>
      {:else if props.toplistFocus == 'lotto'}
        <div class="lotto">
          <Icons name="gift" strokeWidth="2" color="#2ca5e4" />
          <span>{operator?.bonus?.lotto?.[$project.settings.market]?.bonus}</span>
        </div>
      {/if}

      <!-- {#if operator.licenses?.includes($project?.data?.mainLicense)}
        <div class="license">
          <img src="//cdn.casinofeber.se/pics/casinoinfo-svenskt.png?w=22&amp;h=22&amp;q=100&amp;auto=format" loading="lazy" alt="Svensk licens">
          <span><Translate text="Casino has english license" lang={$project?.settings?.market} /></span>
        </div>
      {:else if operator.countries?.includes($project?.settings?.market)}
        <div class="license">
          <img src={ $project.data?.imageFolders?.regions + $project?.settings?.market + ".svg" } width="15px" height="15px" loading="lazy" alt={ $translations["Casino open for english players"][$project.settings.market] }>
          <span><Translate text="Casino open for english players" lang={$project?.settings?.market} /></span>
        </div>
      {/if} -->
    </div>

    <ul class="toplist-pros">
      {#if operator?.rating?.[$project.key]?.prosCasino && props.toplistFocus == 'casino'}
        {#each operator.rating[$project.key].prosCasino as pro}
          {#if pro}
            <li>{pro}</li>
          {/if}
        {/each}
      {:else if operator?.rating?.[$project.key]?.prosBetting && props.toplistFocus == 'sport'}
        {#each operator.rating[$project.key].prosBetting as pro}
          {#if pro}
            <li>{pro}</li>
          {/if}
        {/each}
      {/if}
      {#if operator.licenses?.includes($project?.data?.mainLicense)}
        <li class="license"><Translate text="Casino has english license" lang={$project?.settings?.market}/></li>
      {:else if operator.countries?.includes($project?.settings?.market)}
        <li class="license open"><Translate text="Casino open for english players" lang={$project?.settings?.market}/></li>
      {/if}
    </ul>

    <div class="toplist-cta">
      <a href="/go/{operator.name_lower}" target="_blank" rel="nofollow noreferrer noopener" class="visit">
        <span><Translate text={"Visit" + (props.toplistFocus == "casino" ? " casino" : "") } /></span>
        <span><Translate text="Proceed to" /> { operator["name"] }</span>
      </a>
      <a href={ ($project.data?.categoryPages?.reviews.slug ? "/" + $project.data.categoryPages.reviews.slug : "") + "/" + operator.name_lower} class="review">{ operator["name"] }</a>
    </div>
  </div>

  <div class="toplist-terms">
    {#if operator?.bonus?.casino?.[$project.settings.market]?.tc && props.toplistFocus == 'casino'}
      {@html operator.bonus.casino[$project.settings.market].tc}
    {:else if operator?.bonus?.sports?.[$project.settings.market]?.tc && props.toplistFocus == 'sport'}
      {@html operator.bonus.sports[$project.settings.market].tc}
    {:else}
      <Terms {operator} />
    {/if}
  </div>
</div>
{/if}