<script>
  import { page } from "$app/stores";
  import Input from "$lib/admin/components/Input.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Manage from "$lib/admin/components/Manage.svelte";
  import Icons from '$lib/main/components/Icons.svelte';
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";

  export let props = null;
  export let adminView = false;

  let showLinks = false;
  let innerWidth = 0;

  function add() {
    if (!Array.isArray(props.links)) props.links = []
    props.links.push({anchor: "", url: null, icon: null, description: ""})
    props = props
  }

  function remove(index) {
    props.links.splice(index, 1)
    props = props
  }

  // TODO:
  //   - create group design (mini-boxes, like cards instead of list of links; can take icosn)
</script>

<svelte:window bind:innerWidth />

{#if adminView}
  <Group>
    <Input
      type="select"
      bind:value={props.linksType}
      on:keyup>
      <option value="list">List</option>
      <option value="group">Group</option>
      <option value="slider">Slider</option>
      <option value="single-button">Single button</option>
      <option value="single-button-internal">Single button - internal</option>
    </Input>
    <Input
      type="text"
      placeholder="Header text"
      bind:value={props.linksHeaderText}
      on:keyup />
  </Group>
  {#if props.links}
    {#each props.links as link, index}
      <Group colspan="5-6-1" addClass="form-item narrow fs-xs">
        <Input
          type="text"
          placeholder="Anchor"
          bind:value={link.anchor}
          on:keyup
          />
        <Input
          type="text"
          placeholder="URL"
          bind:value={link.url}
          on:keyup
          />
        <Button addClass="secondary" onlyIcon icon="trash-2" on:click={() => remove(index)} />
      </Group>
      <Group colspan="9-3" addClass="form-item narrow fs-xs">
        <Input
          type="text"
          placeholder="Description"
          bind:value={link.description}
          on:keyup
          />
        <Input
          type="text"
          placeholder="Icon"
          bind:value={link.icon}
          on:keyup
          />
      </Group>
    {/each}
  {/if}

  <Group addClass="form-item manage">
    <Manage>
      <Button addClass="primary" on:click={() => add()}>Add</Button>
    </Manage>
  </Group>
{:else}
  {#if props?.links}
    {#if props.linksType == 'list'}
      <Block blockClass="block-links" {props}>
        <div class="component-links list">
          <button on:click={() => showLinks = !showLinks}>
            <Icons name="menu" strokeWidth="3" />
            <span>{ props.linksHeaderText }</span>
          </button>
          <ul class:show={showLinks}>
            {#each props.links as link}
              <li class:active={$page.data.post?.slug === link.url}>
                <a class:icon={link.icon} href={link.url} on:click={() => showLinks = !showLinks}>
                  {#if link.icon}
                    <Icons name={link.icon} strokeWidth="2" />
                  {/if}
                  <span class="title">{link.anchor}</span>
                  {#if link.description}
                    <span class="description">{link.description}</span>
                  {/if}
                </a>
              </li>
            {/each}
          </ul>
        </div>
      </Block>
    {:else if props.linksType == 'single-button'}
      {#if props.links}
      <Block blockClass="block-links single-button" {props}>
        <a href={props.links[0].url}>{props.links[0].anchor}</a>
      </Block>
      {/if}
    {:else if props.linksType == 'single-button-internal'}
      {#if props.links}
      <Block blockClass="block-links single-button-internal" {props}>
        <a href={props.links[0].url}>{props.links[0].anchor}</a>
      </Block>
      {/if}
    {:else if props.linksType == 'toplistSort'}
      <div class="component-links">
        <button on:click={() => showLinks = !showLinks}>
          <Icons name="menu" strokeWidth="3" />
          <span><Translate text="Sort" /></span>
        </button>
        <ul class:show={showLinks}>
          {#if props.links}
            {#each props.links as item}
              <li class:active={$page.data.post?.slug === item.path}>
                <a class="icon" href={item.path} on:click={() => showLinks = !showLinks}>
                  <Icons name={item.icon} strokeWidth="2" />
                  <span>{item.name}</span>
                </a>
              </li>
            {/each}
          {/if}
        </ul>
      </div>
    {/if}
  {/if}
{/if}

<style lang="postcss">
  /* TODO: copy from _base.postcss */
</style>