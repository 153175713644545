<script>
  import Input from "$lib/admin/components/Input.svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Heading from '$lib/admin/components/blocks/helpers/Heading.svelte';
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';

  // props.listType: select - choose type of list to show
  //  options: ul, ol
  // props.listStyle: select - choose the styling of the discs
  //  options: default, pros, cons, gift
  // props.listItems: array - holds all the list items

  export let props = null;
  export let adminView = false;

  function add() {
    if(!Array.isArray(props.listItems)) props.listItems = []
    props.listItems.push("")
    props = props
  }

  function remove(index) {
    props.listItems.splice(index, 1)
    props = props
  }
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="List type"
      type="select" bind:value={props.listType} on:change>
      <option value="ul">Unordered list</option>
      <option value="ol">Ordered list</option>
    </Input>
    <Input
      label="List type"
      type="select" bind:value={props.listStyle} on:change>
      <option value="">Default</option>
      <option value="pros">Pros</option>
      <option value="cons">Cons</option>
      <option value="gift">Gift</option>
    </Input>
  </Group>
  <Group colspan="1-10-auto" addClass="form-item">
    {#if props.listItems}
      {#each props.listItems as item, index}
        <Input type="text" bind:value={item} label={`Item ${index + 1}`} on:keyup />
        <Button addClass="secondary" on:click={() => remove(index)} onlyIcon icon="trash-2" />
      {/each}
    {/if}
  </Group>
  <Button addClass="primary" icon="plus-square" on:click={() => add()}>Add item</Button>
{:else}
  <Block
    blockClass="block-lists"
    gridColumn="g-col-12 g-start-1 g-col-md-10 g-start-md-2"
    {props}>
    <Heading {props} />

    {#if props.listType}
      <svelte:element
        this={props.listType}
        class={props.listStyle}>
        {#if props.listItems}
          {#each props.listItems as item}
            <li>{@html item}</li>
          {/each}
        {/if}
      </svelte:element>
    {/if}
  </Block>
{/if}
