<script>
  import { project } from '$lib/services/store'
  export let operator = null; // row from 'operators' table

  let minRating = 0;
  let maxRating = 5;

  let rating = operator?.rating?.[$project.key]?.rating || minRating
  let ratingMessage = operator?.rating?.[$project.key]?.rating ? $project.name + " rates " + operator.name + " " + operator?.rating?.[$project.key]?.rating + " out of " + maxRating + " stars" : operator?.name + " has not yet been given a rating by " + $project.name
</script>

<div
  class="item-rating"
  style="--rating: { rating }; --star-color: { "rgb(var(--_" + operator?.name_lower + "))" };"
  aria-label={ratingMessage}
  title={ratingMessage}>
  <span>★★★★★</span>
</div>